<template>
  <div class="orderhouse">
    <navbar title="预约看房" />
    <div class="orderhouse-box">
      <div class="order-house">
        <van-field v-model="likehouse" label="意向房源" placeholder="请填写房源名称" readonly input-align="right" />
      </div>
      <div class="order-name">
        <van-field v-model="username" @input="nameFocus" label="姓名" placeholder="请填写姓名" input-align="right" />
      </div>
      <div class="order-phone">
        <van-field v-model="phone" label="手机号" placeholder="请填写手机号" input-align="right" />
      </div>
    </div>
    <div class="order-words">
      <van-field v-model="words" rows="4" autosize type="textarea" placeholder="预约看房留言" />
    </div>
    <div class="btn" @click="order">
      <van-button type="info">确定</van-button>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import { isMobile, symbolEscape ,escapeHtml} from "../../common/validate";
export default {
  name: "orderHouse",
  data() {
    return {
      likehouse: "",
      username: "",
      phone: "",
      words: "",
    };
  },

  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.likehouse = this.title;
    this.type = this.$route.query.type;
  },
  mounted() {
    this.getUser();
  },

  methods: {
    //预约
    nameFocus(value) {
      this.username = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
    },
    order() {
      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return false;
      }
      if (!isMobile(this.phone)) {
        this.$toast.fail("手机号码格式不正确");
        return false;
      }
      const data = {
        id: this.id,
        title: this.likehouse,
        words: symbolEscape(this.words),
        type: this.type,
        username: this.username,
        phone: this.phone,
      };
      this.$post("user/addBooking", data).then((res) => {
        var this1 = this;
        if (res.status == 200) {
          this.$toast.success({
            message: "预约成功,我们工作人员会尽快跟你联系",
            duration: 1500,
            onClose: function () {
              this1.$router.go(-1);
            },
          });
        } else {
          this.$toast.fail({
            message: res.msg,
            duration: 1500,
            onClose: function () {
              this1.$router.go(-1);
            },
          });
        }
      });
    },
    //获取用户信息
    getUser() {
      this.$post("user/getUserInfo").then((res) => {
        if (res.status) {
          this.username = res.data.userInfo.nickname;
          this.phone = res.data.userInfo.mobile;
        }
      });
    },
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.orderhouse-box {
  padding: 0 20px;
  .order-item {
    width: 335px;
    height: 35px;
    margin-left: 19px;
    margin-top: 11px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #535353;
  }

  /deep/.van-cell {
    font-size: 16px;
    color: #535353;
    border-bottom: 1px solid #dddddd;

    background-color: transparent;
  }
  /deep/.van-field__control {
    // text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.order-words {
  margin-top: 10px;
  padding: 0 20px;
  /deep/.van-cell {
    padding: 0;
    textarea {
      background-color: #fff;
      padding: 8px;
      color: #666;
      font-size: 15px;
    }
  }
}

/deep/.van-field__control {
  font-size: 16px;
}
.btn {
  display: flex;
  justify-content: center;
}
/deep/.van-button--info {
  width: 335px;
  height: 44px;
  background: #00d3c4;
  border: none;
  border-radius: 5px;
  font-size: 19px;
  margin-top: 49px;
}
</style>